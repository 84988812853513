.homepage{
    width: 100%;
    height: auto;
    margin: auto;
    align-items: center;
}

/*THIS IS THE HERO SECTION*/
.homepage .hero-section{
    padding-top: 90px;
    margin: 0;
}

.homepage .hero-section .hero-container{
    max-width: 1280px;
    margin: auto;
    align-items: center;
}

.homepage .hero-section .hero-container .sub-section{
    display: flex;
    justify-content: space-evenly;
}

.homepage .hero-section .hero-container .sub-section .cta-section{
    margin-top: 60px;
    width: 100%;
    margin-left: 3rem;
}

.homepage .hero-section .hero-container .sub-section .cta-section .hero-title p:nth-child(1){
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 100px;
    color: var(--gray);
    line-height: 90px;
}

.homepage .hero-section .hero-container .sub-section .cta-section .hero-title p:nth-child(2){
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 100px;
    color: var(--gray);
    line-height: 90px;
}

.homepage .hero-section .hero-container .sub-section .cta-section .hero-title p:nth-child(3){
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 50px;
    color: var(--gray);
    line-height: 90px;
}



.homepage .hero-section .hero-container .sub-section .cta-section .hero-caption{
    margin-top: 20px;
}

.homepage .hero-section .hero-container .sub-section .cta-section .hero-caption p:nth-child(1){
    font-family: 'Figtree', sans-serif;
    font-size: 19px;
    font-weight: 400;
    color: var(--gray);
}

.homepage .hero-section .hero-container .sub-section .cta-section .hero-caption p:nth-child(2){
    font-family: 'Figtree', sans-serif;
    font-size: 19px;
    font-weight: 400;
    color: var(--gray);
}


.homepage .hero-section .hero-container .sub-section .cta-section .cta-caption{
    margin-top: 30px;
} 

.homepage .hero-section .hero-container .sub-section .cta-section .cta-caption p{
    font-family: 'Figtree', sans-serif;
    font-size: 19px;
    font-weight: 600;
    color: var(--black);
}


.homepage .hero-section .hero-container .sub-section .hero-image-section{
    width: 50%;
    height: 70vh;
}

.homepage .hero-section .hero-container .sub-section .hero-image-section img{
    width: 700px;
    position: relative;
    bottom: 30px;
    right: 25px;
}

.homepage .hero-section .hero-container .sub-section .join-waitlist-btn{
    margin-top: 40px;
    padding: 12px 18px;
    border-radius: 3px;
    border: none;
    width: 35%;
    background-color: #1F4168;
    cursor: pointer;
}

.homepage .hero-section .hero-container .sub-section .join-waitlist-btn:hover{
    background-color: #34567e;
    cursor: pointer;
}

.homepage .hero-section .hero-container .sub-section .join-waitlist-btn .join-waitlist-link{
    text-decoration: none;
    font-family: 'Figtree', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: white;
    letter-spacing: 0.5px;
}

.homepage .hero-section .hero-container .sub-section .join-waitlist-btn .join-waitlist-link .join-waitlist-icon{
    margin-left: 10px;
}






