
.footer{
    height: 27vh;

}

.footer .footer-container{
    padding-top: 35px;
    max-width: 1280px;
    margin: auto;
    align-items: center;
}

.footer .footer-container .socials{
    width: 40vh;
    margin: auto;
    align-items: center;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.footer .footer-container .socials .socials-link i{
    font-size: 30px;
    color: var(--footerColor)
}

.footer .footer-container .socials .socials-link i:hover{
    color: var(--btnColor);
}

.footer .footer-container .copyright{
    padding-top: 20px;
    width: 45vh;
    margin: auto;
    align-items: center;
    justify-content: center;
    display: block;
}

.footer .footer-container .copyright .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0 0;
}

.footer .footer-container .copyright .image-container img {
    max-width: 50%;
    max-height: 40%;
}


.footer .footer-container .copyright .copyright-notice p{
    margin-top: .5rem;
    text-align: center;
    font-family: 'Figtree', sans-serif;
    color: var(--footerColor);
    font-weight: 600;
    font-size: 17px;
}

.footer .footer-container .legal{
    padding-top: 10px;
    width: 45vh;
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: space-between;
    list-style: none;
}

.footer .footer-container .legal .legal-link{
    text-decoration: underline;
}

.footer .footer-container .legal .legal-link p{
    color: var(--footerColor);
    font-weight: 500;
    font-size: 18px;
}
