.problem-section{
    background-color: transparent;
}

.problem-section .section-container{
    max-width: 1280px;
    margin: auto;
    align-items: center;
    padding: 0.5rem 1.5rem;
}

.problem-section .section-container .background-container{
    margin: auto;
    align-items: center;
    padding: 0.25rem 3.5rem 0.25rem;
    border-radius: 10px;
}

.problem-section .section-container  .background-container .header{
    margin: auto;
    align-items: center;
}

.problem-section .section-container .background-container .header p:nth-child(1){
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 55px;
    font-weight: 700;
    color: #363636;
}

.problem-section .section-container .background-container .header p:nth-child(2){
    text-align: center;
    font-family: 'Figtree', sans-serif;
    font-size: 28px;
    font-weight: 500;
    color: #363636;
}

.problem-section .section-container .background-container .content{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 50px 0 10px;
}

.problem-section .section-container .background-container .content .illustration{
    width: 100%;
}

.problem-section .section-container .background-container .content .illustration img{
    width: 600px;
    position: relative;
    left: 50px;
}


.problem-section .section-container .background-container .content .statement{
    width: 100%;
    margin: auto;
    align-items: center;
}

.problem-section .section-container .background-container .content .statement .title{
    font-family: 'Figtree', sans-serif;
    font-size: 35px;
    font-weight: 600;
    color: #363636;
    line-height: 35px;
    padding-bottom: 1rem;
}

.problem-section .section-container .background-container .content .statement .issues{
    margin-top: 15px;
}

.problem-section .section-container .background-container .content .statement .issues .issues-item {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 13px 0;
}


.problem-section .section-container .background-container .content .statement .issues .issues-item p{
    font-family: 'Figtree', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #363636;
    text-align: left;
}

.problem-section .section-container .background-container .content .statement .issues .issues-item .issue-icon i{
    width: 45px;
    display: flex;
}

.problem-section .section-container .background-container .content .statement .issues .issues-item .issue-icon .fa-sharp{
    color: #1F4168;
    font-size: 25px;
}


.problem-section .section-container .background-container .mid-content{
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.problem-section .section-container .background-container .mid-content .illustration{
    width: 100%;
}

.problem-section .section-container .background-container .mid-content .illustration img{
    width: 600px;
    position: relative;
    right: 50px;
}


.problem-section .section-container .background-container .mid-content .statement{
    width: 100%;
    margin: auto;
    align-items: center;
}

.problem-section .section-container .background-container .mid-content .statement .title{
    font-family: 'Figtree', sans-serif;
    font-size: 35px;
    font-weight: 600;
    color: #363636;
    line-height: 35px;
    padding-bottom: 1rem;
}

.problem-section .section-container .background-container .mid-content .statement .issues{
    margin-top: 15px;
}

.problem-section .section-container .background-container .mid-content .statement .issues .issues-item {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 13px 0;
}


.problem-section .section-container .background-container .mid-content .statement .issues .issues-item p{
    font-family: 'Figtree', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #363636;
    text-align: left;
}

.problem-section .section-container .background-container .mid-content .statement .issues .issues-item .issue-icon i{
    width: 45px;
    display: flex;
}

.problem-section .section-container .background-container .mid-content .statement .issues .issues-item .issue-icon .fa-sharp{
    color: #1F4168;
    font-size: 25px;
}

