.navbar {
    background-color: transparent;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    z-index: 20;
    width: 100%;
}
  
.navbar .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    max-width: 1280px;
}
  
.navbar .nav-container .nav-logo {
    align-items: center;
    cursor: pointer;
    flex-grow: 1;
}

.navbar .nav-container .nav-logo img{
    max-width: 250px;
}
  
.navbar .nav-container .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
}

.navbar .nav-container .nav-menu .nav-item {
    margin-right: 1rem;
    line-height: 40px;
}
  
.navbar .nav-container .nav-menu .nav-item  .nav-links {
    color: #1a1919;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.navbar .nav-container .nav-menu .nav-item  .nav-links:hover {
    color: #5151D5;
    transition: 0.1s all ease-out;
}

.navbar .nav-container .nav-menu .nav-item .active {
    color: var(--btnHoverColor)
}
  
.navbar .nav-container .nav-icon {
    display: none;
  }

/*THIS IS THE CSS SECTION OF THE WAITLIST BUTTON*/

.navbar .nav-container .waitlist-btn{
    margin-right: 1.5rem;
    padding: 10px 25px;
    background-color: #1F4168;
    border-radius: 30px;
    outline: none;
    border: none;
    color: var(--white);
    font-size: 20px;
    letter-spacing: 0.1px;
   cursor: pointer;
}

.navbar .nav-container  .waitlist-btn:hover{
    background-color: #34567e;
}

.navbar .nav-container  .waitlist-btn .waitlist-icon{
    margin-left: 13px;
}


.navbar-scroll{
    background: white;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    z-index: 20;
    width: 100%;
}

.navbar-scroll .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1280px;
}
  
.navbar-scroll .nav-container .nav-logo {
    align-items: center;
    cursor: pointer;
    flex-grow: 1;
}

.navbar-scroll .nav-container .nav-logo img{
    max-width: 250px;
}
  
.navbar-scroll .nav-container .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
}

.navbar-scroll .nav-container .nav-menu .nav-item {
    margin-right: 1rem;
    line-height: 40px;
}
  
.navbar-scroll .nav-container .nav-menu .nav-item  .nav-links {
    color: #1a1919;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.navbar-scroll .nav-container .nav-menu .nav-item  .nav-links:hover {
    color: #5151D5;
    font-weight: 400;
    transition: 0.1s all ease-out;
}

.navbar-scroll .nav-container .nav-menu .nav-item .active {
    color: var(--btnHoverColor)
}
  
.navbar-scroll .nav-container .nav-icon {
    display: none;
  }

/*THIS IS THE CSS SECTION OF THE WAITLIST BUTTON*/
.navbar-scroll .nav-container .waitlist-btn{
    margin-right: 1.5rem;
    padding: 10px 25px;
    background-color: #1F4168;
    border-radius: 30px;
    outline: none;
    border: none;
    color: var(--white);
    font-size: 20px;
    letter-spacing: 0.1px;
    cursor: pointer;
}

.navbar-scroll .nav-container .waitlist-btn:hover{
    background-color: #34567e;
}

.navbar-scroll .nav-container .waitlist-btn .waitlist-icon{
    margin-left: 13px;
}






  
@media screen and (max-width: 960px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #fff;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .navbar .nav-container .waitlist-link .waitlist-btn{
        margin-right: 4.5rem;
    }

    .nav-menu.active {
        background: #1f5156;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-item .active {
        color: #ffdd40;
        border: none;
    }
    .nav-links {
        padding: 1.5rem;
        width: 100%;
        display: table;
    }

    .navbar .nav-container .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: var(--black);
    }

    .navbar-scroll .nav-container .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: var(--black);
    }
}