@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://use.typekit.net/gqi4vrh.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Figtree', sans-serif;
}

:root{
  --red: #EC2F3D;
  --gray: #37373A;
  --white: #FFF;
  --black: #000;
  --btnColor: #5151D6;
  --btnHoverColor: #373797;
  --footerColor: #535768;
  --sectionColor1: ##6d7bcc;
}

body{
  margin: auto;
  align-items: center;
  background-color: white;
  overflow-y: scroll;
  min-height: 100vh;
  background: white;
}